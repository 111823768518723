/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import {
  Select,
  Button
} from '@chakra-ui/react';
import { FaPlus, FaAngleLeft } from "react-icons/fa";
import SweetAlert2 from "react-sweetalert2";
import { useNavigate } from "react-router-dom";

const CreateReceiver = () => {

  const initialData: {
    first_name: string;
    last_name: string;
    email: string;
    middle_name: string;
    dob: string;
    dialcode: string;
    phone: string;
    address: string;
    address2: string;
    gender: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    reference: string;
  } = {
    first_name: "",
    last_name: "",
    email: "",
    middle_name: "",
    dob: "",
    dialcode: "",
    phone: "",
    address: "",
    address2: "",
    gender: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    reference: ""
  };

  const [countries, setCountries] = useState<any>(null);
  const [dialcodeList, setDialcodeList] = useState<any>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [newReceiver, setNewReceiver] = useState<any>(initialData);
  const [swalProps, setSwalProps] = useState<any>({});
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/country/get-all-countries`
      );

      let countries = response?.data;
      let dialcodeList: any = [];
      if (countries.length > 0) {
        countries.forEach((c: any) => {
          if (c.dialcode !== null) {
            if (c.dialcode in dialcodeList) {
              dialcodeList[c.dialcode] = dialcodeList[c.dialcode] + ', ' + c.country_name;
            } else {
              dialcodeList[c.dialcode] = '(+' + c.dialcode + ') ' + c.country_name;
            }
          }
        });
      }

      const sortedDialcodeList: any = [];
      Object.keys(dialcodeList).sort().forEach((key: any) => {
        sortedDialcodeList[key] = dialcodeList[key];
      });

      setCountries(countries);
      setDialcodeList(sortedDialcodeList);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setNewReceiver({ ...newReceiver, [id]: value });
  };

  const isFormValid = newReceiver !== null ? (
    newReceiver.first_name
    && newReceiver.last_name
    && newReceiver.email
    && newReceiver.dob
    && newReceiver.dialcode
    && newReceiver.phone
    && newReceiver.address
    && newReceiver.city
    && newReceiver.state
    && newReceiver.postcode
    && newReceiver.country
  ) : false;
  const handleSubmit = async () => {
    if (isFormValid) {
      newReceiver.subaccount_id = localStorage.getItem("subaccountId");
      newReceiver.username = localStorage.getItem("username");
      newReceiver.userRole = userRole;
      try {
        setIsCreating(true);
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/receiver/create`,
          newReceiver
        )
          .then(function (response) {
            if (response && response.data?.status === 'ok') {
              setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: "Create receiver successfully",
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsCreating(false);
                  navigate(`/${userRole}/receiver`)
                },
              });
            } else {
              setIsCreating(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsCreating(false);
                },
              });
            }
          })
          .catch(function (error) {
            setIsCreating(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: error.response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsCreating(false);
              },
            });
          });
      } catch (error: any) {
        setIsCreating(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsCreating(false);
          },
        });
      }
    }
  };

  return (
    <form>
      <Card className="mt-6 card card-pad">
        {countries !== null && dialcodeList !== null ? (
          <>
            <div className="flex flex-wrap">
              <h3 className="mb-4 text-2xl font-bold text-navy-700 dark:text-white">
                Create Receiver
              </h3>
            </div>
            <div className="flex flex-wrap justify-between mt-6">
              <div className="w-full md:w-1/2 md:pr-4">
                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="First Name *"
                  placeholder=""
                  id="first_name"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.first_name}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Middle Name"
                  placeholder=""
                  id="middle_name"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.middle_name}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Last Name *"
                  placeholder=""
                  id="last_name"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.last_name}
                  onChange={handleInputChange}
                />

                <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                  Gender
                </label>

                <Select disabled={isCreating} id="gender" value={newReceiver.gender} onChange={handleInputChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                  <option value="">-- Please Select --</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Select>

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Email *"
                  placeholder=""
                  id="email"
                  type="email"
                  autoComplete="off"
                  value={newReceiver.email}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Date of Birth *"
                  placeholder=""
                  id="dob"
                  type="date"
                  autoComplete="off"
                  value={newReceiver.dob}
                  onChange={handleInputChange}
                />

                <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                  Dialcode *
                </label>

                <Select disabled={isCreating} id="dialcode" value={newReceiver.dialcode} onChange={handleInputChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                  <option value="">-- Please Select --</option>
                  {Object.entries(dialcodeList).map(([key, value]: [any, any]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Select>

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Phone *"
                  placeholder=""
                  id="phone"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.phone}
                  onChange={handleInputChange}
                />
              </div>

              <div className="w-full md:w-1/2 md:pl-4">
                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Address *"
                  placeholder=""
                  id="address"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.address}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Address 2"
                  placeholder=""
                  id="address2"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.address2}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="City *"
                  placeholder=""
                  id="city"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.city}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="State *"
                  placeholder=""
                  id="state"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.state}
                  onChange={handleInputChange}
                />

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Postcode *"
                  placeholder=""
                  id="postcode"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.postcode}
                  onChange={handleInputChange}
                />

                <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                  Country *
                </label>

                <Select disabled={isCreating} id="country" value={newReceiver.country} onChange={handleInputChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                  <option value="">-- Please Select --</option>
                  {countries.map((item: any) => (
                    <option key={item.id} value={item.country_code_3}>
                      {item.country_name}
                    </option>
                  ))}
                </Select>

                <InputField
                  disabled={isCreating}
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Reference"
                  placeholder=""
                  id="reference"
                  type="text"
                  autoComplete="off"
                  value={newReceiver.reference}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-end">
              <div className="mt-4">
                <Button
                  onClick={() => navigate(`/${userRole}/receiver`)}
                  className="mr-3 btn btn-default"
                >
                  <FaAngleLeft />&nbsp;Return to Receivers
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  isDisabled={!isFormValid}
                  isLoading={isCreating}
                  loadingText='Creating...'
                  className="btn btn-primary"
                >
                  <FaPlus />&nbsp;Create Receiver
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          </>
        )}

      </Card >

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

      </SweetAlert2>
    </form>
  );
};

export default CreateReceiver;
