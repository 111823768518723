/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import SweetAlert2 from "react-sweetalert2";
import { Button } from '@chakra-ui/react';
import { IoCheckmark } from "react-icons/io5";

const BatchUpdateStatusConfirmModal: React.FC<{
    batchToUpdateStatus: any;
    statusToUpdate: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
    setSwalProps: any;
}> = ({ isOpen, onClose, triggerReload, batchToUpdateStatus, statusToUpdate, setSwalProps }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const closeModal = async () => {
        onClose();
    };

    const displayStatus = () => {
        let statusLabel = "";
        switch (statusToUpdate) {
            case "in_progress":
                statusLabel = "IN PROGRESS";
                break;
            case "settled":
                statusLabel = "SETTLED";
                break;
            case "failed":
                statusLabel = "FAILED";
                break;
        }

        return statusLabel;
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const postData = {
                id: batchToUpdateStatus.id,
                status: statusToUpdate,
                username: localStorage.getItem("username"),
                userRole: userRole,
                subaccount_id: batchToUpdateStatus.subaccount.id
            }

            await axios.post(process.env.REACT_APP_API_URL + `/api/batch/update-status`, postData).then(function (response) {
                onClose();
                if (response && response.data?.status === 'ok') {                    
                    setSwalProps({
                        show: true,
                        icon: "success",
                        title: "Success",
                        html: "Batch status has been updated",
                        showConfirmButton: true,
                        didClose: () => {
                            triggerReload();
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                } else {
                    setIsProcessing(false);
                }
            }).catch(function (error) {
                setIsProcessing(false);
                setSwalProps({
                    show: true,
                    icon: "error",
                    title: "Oops!",
                    html: error.response.data.message,
                    showConfirmButton: true,
                    didClose: () => {
                        setSwalProps({});
                        setIsProcessing(false);
                    },
                });
            });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const displayAmount = (amount: any) => {
        if (amount === 0) {
            return "$0.00";
        }
        return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {batchToUpdateStatus &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody>
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to update the status?</h1>
                                <div className="mb-[20px]">
                                    <div className="mb-8">
                                        Please confirm if you wish to update the current batch status to <strong>{displayStatus()}</strong>. This action cannot be undone.
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Type:</span> {batchToUpdateStatus.batch_type}
                                    </div>
                                    {userRole === 'superadmin' &&
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">File:</span>
                                            {batchToUpdateStatus.file_bucket !== null && batchToUpdateStatus.file_key !== null ?
                                                <>
                                                    {batchToUpdateStatus.file_name}
                                                </>
                                                :
                                                "-"
                                            }
                                        </div>
                                    }
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Record(s):</span> {batchToUpdateStatus.record}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Amount:</span> {displayAmount(batchToUpdateStatus.amount)} {batchToUpdateStatus.asset}
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isLoading={isProcessing}
                                        loadingText='Processing...'
                                        className="btn btn-primary">
                                        <IoCheckmark className="h-4 w-4" />&nbsp;Confirm
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default BatchUpdateStatusConfirmModal;
