/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "assets/img/logo-light.svg";
import { Button } from "@chakra-ui/react";
import Card from "components/card";

const MerchantSelection: React.FC = () => {
  const [merchants, setMerchants] = useState<{ id: string; name: string }[]>(
    []
  );
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState(null);

  const cognitoId = location.state?.cognitoId;
  const userRole = location.state?.userRole;
  const userEmail = localStorage.getItem("userEmail");
  const apiToken = localStorage.getItem("apiToken");

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      sessionStorage.setItem("attemptedRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    if (sessionStorage.getItem("attemptedRefresh") === "true") {
      sessionStorage.removeItem("attemptedRefresh");
      navigate("/auth/sign-in");
    }

    if (!cognitoId || !userRole) {
      navigate("/auth/sign-in");
      return;
    }

    const fetchMerchants = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/merchant/get-all-merchants-user/${cognitoId}`,
          {
            headers: {
              Authorization: `Bearer ${apiToken}`
            }
          }
        );
        const data = response.data.merchants;
        if (Array.isArray(data)) {
          setMerchants(
            data.map((merchant: any) => ({
              id: merchant.id,
              name: merchant.name,
            }))
          );
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching merchants:", error);
      }
      setIsLoading(false);
    };

    if (cognitoId) {
      fetchMerchants();
    }
  }, [cognitoId]);

  useEffect(() => {
    if (merchants.length === 1) {
      localStorage.setItem("merchantId", merchants[0].id);
      // localStorage.setItem("merchantName", merchants[0].name);
      localStorage.setItem("merchantsCount", '1');
      navigate("/auth/subaccount-selection", {
        state: { merchantId: merchants[0].id, cognitoId },
      });
    } else {
      localStorage.setItem("merchantsCount", merchants.length.toString());
    }
  }, [merchants, cognitoId, navigate]);

  const handleMerchantSelection = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMerchant(event.target.value);

    let merchant = merchants.filter((x: any) => x.id === event.target.value)[0];
    // localStorage.setItem("merchantName", merchant.name);
  };

  const handleConfirmSelection = () => {
    setIsWaiting(true);
    setError(null);
    try {
      localStorage.setItem("merchantId", selectedMerchant);

      const selectedMerchantRole = userRole.find(
        (role: any) => role.merchantId === selectedMerchant
      );
  
      if (selectedMerchantRole) {
        navigate("/auth/subaccount-selection", {
          state: { merchantId: selectedMerchant, cognitoId, userRole },
        });
      }
    } catch (error) {
      setIsWaiting(false);
      setError("Something went wrong, please contact administrator");
      console.error("Error fetching user info: ", error);
    }
  };

  return Logo ? (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0">
        <div className="mb-8 flex items-center justify-center">
          <img src={Logo} alt="Payswiftly" width="229px" />
        </div>
        <Card
          className="bg-white p-8 sm:rounded-md shadow-3xl"
        >
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Select Merchant
          </h4>
          <p className="mb-12 text-base text-gray-600">
            Please select which merchant you want to manage
          </p>
          <p className="mb-3 text-sm text-gray-600">
            Signed in as{" "}
            <strong className="text-green-500">{userEmail}</strong>
          </p>
          <div className="text-black-600 mb-3 flex justify-center text-base">
            <select
              disabled={merchants.length === 0 || isLoading}
              value={selectedMerchant}
              onChange={handleMerchantSelection}
              className="custom-select relative mt-2 block w-full rounded-lg border border-gray-200 bg-white p-2.5 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
              {!merchants ? (
                <option>Loading...</option>
              ) : merchants.length === 0 || isLoading ? (
                <option>Retreiving Merchants...</option>
              ) : (
                <>
                  <option disabled value="">-- Please Select --</option>
                  {merchants.map((merchant) => (
                    <option key={merchant.id} value={merchant.id}>
                      {merchant.name}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>

          {error &&
            <p className="flex justify-center text-sm text-red-500 my-2">
              {error}
            </p>
          }

          <Button
            onClick={handleConfirmSelection}
            isDisabled={!selectedMerchant}
            isLoading={isWaiting}
            loadingText="Please wait..."
            className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
          >
            Continue
          </Button>
        </Card>
      </div>
    </div>
  ) : null;
};

export default MerchantSelection;
