/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRenderCellParams, GridClasses } from "@mui/x-data-grid";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineDownload } from "react-icons/ai";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type RowData = {
  date: string;
  currency: string;
  report: any[];
  merchant_name: string;
  subaccount_name: string;
};

const BatchFailedReportList: React.FC<{
  isDownloading: boolean;
  setIsDownloading: any;
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
}> = ({
  isDownloading,
  setIsDownloading,
  isLoading,
  tableData,
  page,
  pageSize,
  rowCount,
  setPaginationModel,
  currentLastKey
}) => {
    const columns: GridColDef[] = [      
      {
        field: "date",
        headerName: "Process Date",
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          formatDate(params.row.date),
      },
      { field: "currency", headerName: "Asset", headerAlign: 'center', align: 'center', sortable: false, flex: 1 },
      {
        field: "total", headerName: "Total", headerAlign: 'center', align: 'center', sortable: false, flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          params.row?.report.length,
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title="Download" arrow placement="right">
              <div className="mr-10">
                <IconButton
                  id={"button-download-" + params?.row?.date}
                  onClick={() => handleClickDownload(params.row.date)}
                  disabled={isDownloading}
                >
                  <AiOutlineDownload className="h-5 w-5 text-navy-700" />
                </IconButton>
              </div>
            </Tooltip>
          );
        },
      },
    ];

    const handleClickDownload = async (date: string) => {

      setIsDownloading(true);

      const data = await tableData.filter(x => x.date === date);
      const headers = Object.keys(data[0].report[0]);
      const csvContent = "data:text/csv;charset=utf-8," + headers.join(',') + '\n' + data[0].report.map(item => Object.values(item).join(",")).join("\n");

      const dateForFileName = moment(new Date(Number(date)).toLocaleString()).format("DD-MMM-YY");
      const fileName = `${data[0].merchant_name + '_' + data[0].subaccount_name + '_' + data[0].currency + '_' + dateForFileName}.csv`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      setTimeout(() => {
        link.click();
        setIsDownloading(false);
      }, 2000);
    };

    const formatDate = (timestamp: string) => {
      return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY");
    };

    const customDataGridStyles: Partial<GridClasses> = {
      root: 'mui-table-custom',
      columnHeader: 'text-start text-md',
      row: ''
    };

    return (
      <Box
        className="mt-6 w-full card"
      >
       
          <DataGrid
              sx={{ 
                '& .MuiDataGrid-cell': {
                  padding: '8px 16px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '8px 16px',
                },
                "& .MuiTablePagination-input": {
                  marginRight: "-20px"
                },
                "& .MuiTablePagination-displayedRows": {
                  display: "none"
                },
              }} 
            loading={isLoading}
            rows={tableData || []}
            columns={columns}
            getRowId={(row) => row.date}
            rowCount={rowCount}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[10, 25, 50, 100]}
            rowHeight={48}
            columnHeaderHeight={48}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            classes={customDataGridStyles}
            hideFooter={currentLastKey === "" && page === 0}
            autoHeight
          />
      </Box>
    );
  };

export default BatchFailedReportList;
