import { Navigate } from "react-router-dom";

import MainDashboard from "views/admin/default";

import SignIn from "views/auth/SignIn";
import SubaccountSelection from "views/auth/SubaccountSelection";
import ForgotPassword from "views/auth/ForgotPassword";
import AccountConfirmation from "views/public/AccountConfirmation";
import NotFound from "views/public/NotFound";
import Error from "views/public/Error";

import { MdLock } from "react-icons/md";

import {
  AiOutlineStock,
  AiOutlineUserSwitch,
  AiOutlineProfile
} from "react-icons/ai";
import {
  PiUsersThree,
  PiCurrencyCircleDollar,
  PiStack,
  PiCreditCard,
} from "react-icons/pi";

import { GrTransaction } from "react-icons/gr";
import { FiActivity } from "react-icons/fi";

import UserManagement from "views/admin/user";
import UserDetail from "views/admin/user/UserDetail";
import Payout from "views/admin/payout";
import Transaction from "views/admin/transaction";
import CreatePayout from "views/admin/payout/CreatePayout";
import MerchantSelection from "views/auth/MerchantSelection";
import Batch from "views/admin/batch";
import BatchDetail from "views/admin/batch/BatchDetail";
import BatchFailedReport from "views/admin/batch/BatchFailedReport";
import Receiver from "views/admin/receiver";
import CreateReceiver from "views/admin/receiver/CreateReceiver";
import ReceiverDetail from "views/admin/receiver/ReceiverDetail";
import AccountManagement from "views/admin/account";
import AccountDetail from "views/admin/account/AccountDetail";
import ActivityLogs from "views/admin/activitylog";

const useRoutes = (userPermissions: any | null) => {

  const userRole = localStorage.getItem("userRole");
  const merchantId = localStorage.getItem("merchantId");
  const subaccountId = localStorage.getItem("subaccountId");

  const permissionKey = `${merchantId}-${subaccountId}`;

  const authRoutes = [
    {
      isSideBar: false,
      name: "Select Merchant",
      layout: "/auth",
      path: "merchant-selection",
      icon: <MdLock className="h-6 w-6" />,
      component: <MerchantSelection />,
    },
    {
      isSideBar: false,
      name: "Select Subaccount",
      layout: "/auth",
      path: "subaccount-selection",
      icon: <MdLock className="h-6 w-6" />,
      component: <SubaccountSelection />,
    },
    {
      isSideBar: false,
      name: "Sign In",
      layout: "/auth",
      path: "sign-in",
      icon: <MdLock className="h-6 w-6" />,
      component: <SignIn />,
    },
    {
      isSideBar: false,
      name: "Forgot Password",
      layout: "/auth",
      path: "forgot-password",
      icon: <></>,
      component: <ForgotPassword />,
    }
  ];

  const publicRoutes = [
    {
      isSideBar: false,
      name: "Payout Confirmation",
      layout: "/",
      path: "confirm/:token",
      icon: <></>,
      component: <AccountConfirmation />,
    },
    {
      isSideBar: false,
      name: "Not Found",
      layout: "/",
      path: "404",
      icon: <></>,
      component: <NotFound />,
    },
    {
      isSideBar: false,
      name: "Internal Error",
      layout: "/",
      path: "500",
      icon: <></>,
      component: <Error />,
    }
  ];

  const getRoutesByPermission = () => {
    const routes = [];

    if (userRole === "SuperAdmin") {

      routes.push({
        isSideBar: true,
        name: "Dashboard",
        layout: `/${userRole?.toLowerCase() || ""}`,
        path: "default",
        icon: <AiOutlineStock className="h-6 w-6" />,
        component: <MainDashboard />,
      });

      routes.push({
        isSideBar: true,
        name: "Payouts",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiCurrencyCircleDollar className="h-6 w-6" />,
        path: "payouts",
        component: <Payout />,
      });

      routes.push({
        isSideBar: true,
        name: "Transactions",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <GrTransaction className="h-5 w-6" />,
        path: "transactions",
        component: <Transaction />,
      });

      routes.push({
        isSideBar: true,
        name: "Batches",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiStack className="h-6 w-6" />,
        path: "batch",
        component: <Batch />,
      });

      routes.push({
        isSideBar: false,
        name: "Batch Detail",
        layout: `/superadmin`,
        icon: <></>,
        path: "batch/:id",
        component: <BatchDetail />,
      });

      routes.push({
        isSideBar: true,
        name: "Accounts",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <AiOutlineProfile className="h-6 w-6" />,
        path: "account-management",
        component: <AccountManagement />,
      });

      routes.push({
        isSideBar: false,
        name: "Accounts",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <AiOutlineProfile className="h-6 w-6" />,
        path: "account-management/:subaccount_id",
        component: <AccountDetail />,
      });

      routes.push({
        isSideBar: true,
        name: "Receivers",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <AiOutlineUserSwitch className="h-6 w-6" />,
        path: "receiver",
        component: <Receiver />,
      });
      routes.push({
        isSideBar: false,
        name: "Receivers",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "receiver/create",
        component: <CreateReceiver />,
      });
      routes.push({
        isSideBar: false,
        name: "Receivers",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "receiver/:id",
        component: <ReceiverDetail />,
      });

      routes.push({
        isSideBar: true,
        name: "Activity Logs",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <FiActivity className="h-6 w-6" />,
        path: "activity-logs",
        component: <ActivityLogs />,
      });

      routes.push({
        isSideBar: true,
        name: "User Management",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiUsersThree className="h-6 w-6" />,
        path: "user-management",
        component: <UserManagement />,
      });

      routes.push({
        isSideBar: false,
        name: "User Management",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "user-management/:user_id",
        component: <UserDetail />,
      });

    } else if (userPermissions && userPermissions[permissionKey]) {

      if (userPermissions[permissionKey].viewDashboard) {
        routes.push({
          isSideBar: true,
          name: "Dashboard",
          layout: `/${userRole?.toLowerCase() || ""}`,
          path: "default",
          icon: <AiOutlineStock className="h-6 w-6" />,
          component: <MainDashboard />,
        });
      }

      if (userPermissions[permissionKey].createPayout) {
        routes.push({
          isSideBar: true,
          name: "Create Payout",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiCreditCard className="h-6 w-6" />,
          path: "create-payout",
          component: <CreatePayout />,
        });
      }
      if (userPermissions[permissionKey].viewPayout) {
        routes.push({
          isSideBar: true,
          name: "Payouts",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiCurrencyCircleDollar className="h-6 w-6" />,
          path: "payouts",
          component: <Payout />,
        });
      }
      
      if (userPermissions[permissionKey].viewTransaction) {
        routes.push({
          isSideBar: true,
          name: "Transactions",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <GrTransaction className="h-5 w-6" />,
          path: "transactions",
          component: <Transaction />,
        });
      }

      if (userPermissions[permissionKey].viewBatch) {

        // Start Batches + Submenu
        routes.push({
          isSideBar: true,
          name: "Batches",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiStack className="h-6 w-6" />,
          path: "batch",
          component: <Navigate to={`/${userRole?.toLowerCase() || ""}/batch/manage`} replace />,
          hasSubMenu: true,
          subMenuItems: [
            {
              name: "Manage",
              layout: `/${userRole?.toLowerCase() || ""}`,
              path: "batch/manage",
            },
            {
              name: "Failed Report",
              layout: `/${userRole?.toLowerCase() || ""}`,
              path: "batch/failed-report",
            },
          ]
        });

        routes.push({
          isSideBar: false,
          name: "Batches",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "batch/manage",
          component: <Batch />,
        });

        routes.push({
          isSideBar: false,
          name: "Batches Failed Report",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "batch/failed-report",
          component: <BatchFailedReport />,
        });
        // // End Batches + Submenu

        routes.push({
          isSideBar: false,
          name: "Batch Detail",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "batch/:id",
          component: <BatchDetail />,
        });
      }

      if (userPermissions[permissionKey].viewReceiver) {
        routes.push({
          isSideBar: true,
          name: "Receivers",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <AiOutlineUserSwitch className="h-6 w-6" />,
          path: "receiver",
          component: <Receiver />,
        });
        routes.push({
          isSideBar: false,
          name: "Receivers",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "receiver/create",
          component: <CreateReceiver />,
        });
        routes.push({
          isSideBar: false,
          name: "Receivers",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "receiver/:id",
          component: <ReceiverDetail />,
        });
      }

      if (userPermissions[permissionKey].viewUserManagement) {
        routes.push({
          isSideBar: true,
          name: "User Management",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiUsersThree className="h-6 w-6" />,
          path: "user-management",
          component: <UserManagement />,
        });
        routes.push({
          isSideBar: false,
          name: "User Management",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "user-management/:user_id",
          component: <UserDetail />,
        });
      }
    }

    return routes;
  };

  const routes: any = {
    public: publicRoutes,
    auth: authRoutes,
    ...(userRole
      ? { [userRole.toLowerCase() || ""]: getRoutesByPermission() }
      : {}),
  };

  return { routes, getRoutesByPermission };
};

export default useRoutes;
