import React, { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import { Badge } from '@chakra-ui/react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import PayoutCancelConfirmModal from "../../shared/PayoutCancelConfirmModal";
import { Tooltip, IconButton } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type Subaccount = {
  id: string;
  name: string;
};
type Merchant = {
  id: string;
  name: string;
};

type ReceiverAccount = {
  id: string;
  email: string;
  bsb: string;
  bank_code: string;
  account_number: string;
};

type PayoutProvider = {
  id: string;
  name: string;
};

type RowData = {
  id: string;
  status: string;
  amount: string;
  asset: string;
  created_at: string;
  note: string;
  merchant: Merchant;
  subaccount: Subaccount;
  transactions: any[];
  receiver_account: ReceiverAccount;
  payout_provider: PayoutProvider;
  cause: any;
  reference_tracking: any;
};

const BatchPayoutList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
  triggerReloadPayoutList: any
  batchType: any;
  batchFileBucket: any;
  batchFileKey: any;
}> = ({ isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey, triggerReloadPayoutList, batchType, batchFileBucket, batchFileKey }) => {

  const [payoutToCancel, setPayoutToCancel] = useState<any>(null);
  const [isOpenPayoutCancelConfirmModal, setIsOpenPayoutCancelConfirmModal] = useState<boolean>(false);
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  interface PayoutStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }


  const statusClasses = 'status';

  const statusList: PayoutStatusItems[] = [
    { status: "awaiting", value: 'awaiting', display: <Badge className={`${statusClasses} status-awaiting`}>Awaiting</Badge> },
    { status: "sent_mail", value: 'sent_mail', display: <Badge className={`${statusClasses} status-sent_mail`}>Email Sent</Badge> },
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "unassessed", value: 'unassessed', display: <Badge className={`${statusClasses} status-unassessed`}>Unassessed</Badge> },
    { status: "in_progress", value: 'in_progress', display: <Badge className={`${statusClasses} status-in_progress`}>In Progress</Badge> },
    { status: "successful", value: 'successful', display: <Badge className={`${statusClasses} status-successful`}>Completed</Badge> },
    { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
    { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
    { status: "successful_pending_kyc", value: 'successful_pending_kyc', display: <Badge className={`${statusClasses} status-successful_pending_kyc`}>Successful Pending KYC</Badge> }
  ];

  const columns: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Created Date",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "amount", headerName: "Amount", minWidth: 80, sortable: false, headerAlign: 'right', align: 'right',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        displayAmount(params.row.amount)
    },
    { field: "asset", headerName: "Asset", minWidth: 60, sortable: false, headerAlign: 'center', align: 'center' },
    {
      field: "merchant",
      headerName: "Merchant",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.merchant.name,
    },
    {
      field: "subaccount",
      headerName: "Subaccount",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.subaccount.name,
    },
    {
      field: "bsb",
      headerName: "BSB",
      sortable: false,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.receiver_account.bsb,
    },
    {
      field: "account_number",
      headerName: "Account No.",
      sortable: false,
      minWidth: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.receiver_account.account_number,
    },
    { field: "note", headerName: "Note", sortable: false, flex: 1 },
    { field: "reference_tracking", headerName: "Ref. Tracking", minWidth: 170, sortable: false },
    {
      field: "status", headerName: "", sortable: false, flex: 1,         
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const status = params.value as string;
        const statusItem = statusList.find(item => item.status === status);      
        return (
          <>
            {userRole === 'superadmin' && status === 'failed' && params.row.cause && params.row.cause.fail ? (
              <Tooltip title={params.row.cause.fail} arrow placement="top">
                {statusItem ? statusItem.display : null}
              </Tooltip>
            ) : (
              <>
                {statusItem ? statusItem.display : null}
              </>
            )}
          </>
        );
      }
    },
    {
      field: "actions", headerName: "", sortable: false, 
      flex: 1, headerAlign: 'right', align: 'right',
      renderCell: (params) => {
        return (
          params?.row?.status === 'awaiting' 
          || params?.row?.status === 'sent_mail' 
          || params?.row?.status === 'pending'
          ) 
          && batchType === 'API' 
          && batchFileBucket === null
          && batchFileKey === null ? (
          <Tooltip title="Cancel" arrow placement="right">
            <div className="mr-5">
              <IconButton
                id={"button-detail-" + params?.row?.id}
                onClick={() => handleClickCancel(params.row.id)}
              >
                <MdOutlineCancel className="h-5 w-5 text-red-500" />
              </IconButton>
            </div>
          </Tooltip>
        ) : null;
      },
    },
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
  };

  const displayAmount = (amount: any) => {
      if (amount === 0) {
          return "$0.00";
      }
      return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start text-md',
    row: ''
  };

  const filterColumns = () => {
    const userRole = localStorage.getItem("userRole")?.toLowerCase();
    let rebuildColumns = [];
    if (userRole !== "superadmin") {
      const excludeFields = ["merchant", "subaccount"];
      rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
    } else {
      rebuildColumns = columns;
    }
    return rebuildColumns;
  };

  const handleClickCancel = (id: any) => {
    const payout = tableData.filter((x: any) => x.id === id)[0];
    setPayoutToCancel(payout);
    showPayoutCancelConfirmModal();
  };

  const showPayoutCancelConfirmModal = () => {
    setIsOpenPayoutCancelConfirmModal(true);
  }

  const closePayoutCancelConfirmModal = () => {
    setPayoutToCancel(null);
    setIsOpenPayoutCancelConfirmModal(false);
  }

  return (
    <>
      <PayoutCancelConfirmModal triggerReload={triggerReloadPayoutList} isOpen={isOpenPayoutCancelConfirmModal} payoutToCancel={payoutToCancel} onClose={() => closePayoutCancelConfirmModal()} />
      <Box
        className="w-full card"
      >
        <DataGrid
              sx={{ 
                '& .MuiDataGrid-cell': {
                  padding: '8px 16px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '8px 16px',
                },
                "& .MuiTablePagination-input": {
                  marginRight: "-20px"
                },
                "& .MuiTablePagination-displayedRows": {
                  display: "none"
                },
              }} 
          loading={isLoading}
          rows={tableData || []}
          columns={filterColumns()}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={48}
          columnHeaderHeight={48}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>
    </>
  );
};

export default BatchPayoutList;
