/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import { Button, Radio, RadioGroup, Stack, FormLabel } from '@chakra-ui/react';

const AccountLedgerAdjustmentTab: React.FC<{
  detail: any;
  setSwalProps: any;
  setIsProcessing: any;
  isProcessing: boolean;
  countries: any;
  dialcodeList: any;
}> = ({ detail, setSwalProps, setIsProcessing, isProcessing, countries, dialcodeList }) => {

  const initialData: {
    amount: string;
    reference: string;
  } = {
    amount: "",
    reference: ""
  };

  const [accountDetailForEdit, setAccountDetailForEdit] = useState<any>(null);
  const [ledgerAdjustmentData, setLedgerAdjustmentData] = useState<any>(initialData);
  const [selectedType, setSelectedType] = useState<any>('deposit');

  useEffect(() => {
    setAccountDetailForEdit(detail);
  }, [detail]);

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setLedgerAdjustmentData({ ...ledgerAdjustmentData, [id]: value });
  };

  const handleRadioButtonChange = (event: any) => {
    setSelectedType(event.target.value);
  };

  const isFormValid = accountDetailForEdit && accountDetailForEdit.id &&
    selectedType &&
    ledgerAdjustmentData?.amount &&
    ledgerAdjustmentData?.reference;

  const handleSubmit = async () => {
    if (isFormValid) {

      const postData = {
        accountid: accountDetailForEdit.id,
        type: selectedType,
        amount: ledgerAdjustmentData.amount,
        reference: ledgerAdjustmentData.reference,
        username: localStorage.getItem("username"),
        userRole: localStorage.getItem("userRole")
      }

      try {
        setIsProcessing(true);
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/subaccount/ledger-adjustment`,
          postData
        )
          .then(function (response) {
            if (response && response.data?.status === 'ok') {
              setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: `You have successfully created a ${selectedType} transaction!`,
                showConfirmButton: false,
                timer: 2000,
                didClose: () => {
                  setSwalProps({});
                  setIsProcessing(false);
                  setLedgerAdjustmentData(initialData);
                },
              });
            } else {
              setIsProcessing(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsProcessing(false);
                },
              });
            }
          })
          .catch(function (error) {
            setIsProcessing(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: error.response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsProcessing(false);
              },
            });
          });
      } catch (error) {
        setIsProcessing(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsProcessing(false);
          },
        });
      }
    }
  };

  return (
    <>
      {accountDetailForEdit !== null ? (
        <>
          <div className="flex flex-wrap">
            <h3 className="mb-2.5 text-1xl text-navy-700 dark:text-white">
              * Indicates a required field
            </h3>
          </div>
          <div className="flex flex-wrap justify-between mt-6">
            <div className="w-full md:w-1/2 md:pr-4">

              <div className="mb-3">
                <label className="mb-1 text-sm text-navy-700 dark:text-white" htmlFor="type">
                  Type *
                </label>
                <div className="flex items-center mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="type"
                      value="deposit"
                      checked={selectedType === 'deposit'}
                      onChange={handleRadioButtonChange}
                      className="form-radio text-primary cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-navy-700 cursor-pointer">Deposit</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      disabled
                      type="radio"
                      name="type"
                      value="withdrawal"
                      checked={selectedType === 'withdrawal'}
                      onChange={handleRadioButtonChange}
                      className="form-radio text-primary cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-navy-700 cursor-pointer">Withdrawal</span>
                  </label>
                </div>
              </div>

              <InputField
                disabled={isProcessing}
                variant="auth"
                extra="mb-3 shadow-sm number-input"
                label="Amount *"
                placeholder=""
                id="amount"
                type="number"
                autoComplete="off"
                value={ledgerAdjustmentData.amount > 0 ? ledgerAdjustmentData.amount : ""}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isProcessing}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Transaction Reference *"
                placeholder=""
                id="reference"
                type="text"
                autoComplete="off"
                value={ledgerAdjustmentData.reference}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-end">
            <div className="mt-4">
              <Button
                onClick={() => handleSubmit()}
                isDisabled={!isFormValid || isProcessing}
                isLoading={isProcessing}
                loadingText='Processing...'
                className="btn btn-primary"
              >
                Process
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
        </>
      )}
    </>
  );
};

export default AccountLedgerAdjustmentTab;
