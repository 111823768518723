/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useState, useMemo, useCallback } from "react";
import SweetAlert2 from "react-sweetalert2";
import { CognitoIdentityServiceProvider } from "aws-sdk";

interface DeleteRoleModalProps {
  username: string;
  email: string;
  merchantName: string;
  subaccountname: string;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRole: any;
  setUserRoles: any;
  editUserChannel: BroadcastChannel;
  fetchUserRoles: any;
  setIsDeleting: any;
}

const DeleteRoleModal: React.FC<DeleteRoleModalProps> = ({
  username,
  email,
  merchantName,
  subaccountname,
  showForm,
  setShowForm,
  selectedRole,
  setUserRoles,
  editUserChannel,
  fetchUserRoles,
  setIsDeleting
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [swalProps, setSwalProps] = useState<any>({});

  const cognitoIdentityServiceProvider = useMemo(
    () =>
      new CognitoIdentityServiceProvider({
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      }),
    []
  );

  const handleRoleRemove = useCallback(
    async (role: string, username: string) => {
      await cognitoIdentityServiceProvider
        .adminRemoveUserFromGroup({
          GroupName: role,
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        })
        .promise();
    },
    [cognitoIdentityServiceProvider]
  );

  const handleDelete = async () => {
    setIsSubmitting(true);
    setIsDeleting(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/user/delete-user-role`,
        {
          data: {
            email: email,
            role: selectedRole?.role,
            merchantId: selectedRole?.merchantId,
            subaccountId: selectedRole?.subaccountId,
          },
        }
      );

      await handleRoleRemove(selectedRole?.role, username);

      setShowForm(false);
      setSwalProps({
        show: true,
        icon: "success",
        title: "Success",
        html: "Role has been deleted!",
        showConfirmButton: true,
        didClose: () => {
          setIsDeleting(false);
          setSwalProps({});
          setIsSubmitting(false);
          fetchUserRoles(true);
        },
      });
    } catch (error) {
      console.error("Error deleting user role:", error);
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Failed to delete role. Please try again.",
        showConfirmButton: true,
        didClose: () => {
          setSwalProps({});
          setIsSubmitting(false);
          setIsDeleting(false);
        },
      });
    }
  };

  return (
    <>
      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

      </SweetAlert2>
      <SweetAlert2
        show={showForm}
        title="Are you sure?"
        html={`Are you sure you want to unassign the role <b>${selectedRole?.role}</b> from merchant <b>${merchantName}</b> and subaccount <b>${subaccountname}</b>?`}
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        reverseButtons
        didClose={() => {
          setShowForm(false);
          setIsSubmitting(false);
        }}
        onConfirm={handleDelete}
        customClass={{
          actions: "custom-swal2-popup-action",
          title: "custom-swal2-popup-title",
          confirmButton:
            "custom-swal2-popup-action custom-swal2-popup-action-confirm btn btn-primary",
          cancelButton:
            "custom-swal2-popup-action custom-swal2-popup-action-cancel btn btn-default",
        }}
      />
    </>
  );
};

export default DeleteRoleModal;
