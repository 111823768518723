/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { FaAngleLeft, FaPlus } from "react-icons/fa";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import { Box } from "@chakra-ui/react";
import AddRoleModal from "./components/modal/AddRoleModal";
import EditRoleModal from "./components/modal/EditRoleModal";
import DeleteRoleModal from "./components/modal/DeleteRoleModal";
import InputField from "components/fields/InputField";
import SweetAlert2 from "react-sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import moment from "moment";
import { AiOutlineForm, AiOutlineClose } from "react-icons/ai";
import { Tooltip, IconButton } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

const editUserChannel = new BroadcastChannel("edit-user-update");

type UserRole = {
  role: string;
  merchantId: string;
  subaccountId: string;
};

const rowPerPage = 10;

const UserDetail = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const { user_id } = useParams();
  const [userDetail, setUserDetail] = useState(null);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSetSubaccounts, setIsSetSubaccounts] = useState(false);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<UserRole | null>(null);
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState<string>("details");

  const [updatedUser, setUpdatedUser] = useState({
    username: "",
    email: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [swalProps, setSwalProps] = useState<any>({});

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [roleHierarchy, setRoleHierarchy] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [subaccountForGetNameList, setSubaccountForGetNameList] = useState([]);
  const [deleteRoleMerchantName, setDeleteRoleMerchantName] = useState<string>("");
  const [deleteRoleSubaccountName, setDeleteRoleSubaccountName] = useState<string>("");
  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  const currentUserRole = localStorage.getItem("userRole");

  const formatDate = (dateString: string) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("DD MMM YY - hh:mm A");
  };

  const canEdit = (
    currentUserRole: string,
    targetUserRole: string
  ): boolean => {
    const currentUserRoleLevel = roleHierarchy.find(
      (role) => role.name === currentUserRole
    )?.hierarchy_level;
    const targetUserRoleLevel = roleHierarchy.find(
      (role) => role.name === targetUserRole
    )?.hierarchy_level;

    return (currentUserRoleLevel > targetUserRoleLevel) && userRoles.length > 1;
  };

  const setPaginationModel = (newValue: any) => {
    setPage(newValue.page);
    setPageSize(newValue.pageSize);
  };

  const handleAddRole = () => {
    setShowAddRoleModal(true);
  };

  const handleEditRole = (role: UserRole) => {
    setSelectedRole(role);
    setShowEditRoleModal(true);
  };

  const handleDeleteRole = (role: UserRole) => {
    setSelectedRole(role);

    const merchantId = role.merchantId;
    const merchant = merchants.filter((x: any) => x.id === merchantId);
    const merchantName = merchant.length > 0 ? merchant[0].name : "";
    setDeleteRoleMerchantName(merchantName);

    const subaccountId = role.subaccountId;
    const subaccount = subaccountForGetNameList.filter((x: any) => x.id === subaccountId);
    const subaccountName = subaccount.length > 0 ? subaccount[0].suba_name : "";
    setDeleteRoleSubaccountName(subaccountName);
    
    setShowDeleteRoleModal(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleUpdateUser = async () => {
    setIsUpdating(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user/update-user?email=${userDetail.email}`,
        updatedUser
      );
      setSwalProps({
        show: true,
        icon: "success",
        title: "Success",
        html: "User information successfully updated!",
        showConfirmButton: false,
        timer: 2000,
        didClose: () => {
          setSwalProps({});
          setIsUpdating(false);
        },
      });
    } catch (error: any) {
      console.error("Error updating user:", error.response.data);
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Failed to update user. Username or Email already existed.",
        showConfirmButton: true,
        didClose: () => {
          setSwalProps({});
          setIsUpdating(false);
        },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchUserDetail();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserRoles();
      await fetchHierarchy();
      await getMerchantOptions();
      await getSubaccountForGetName();
      await getRoleOptions();
      setIsLoading(false);
    };

    if(userDetail !== null) {
      fetchData();
    }
  }, [userDetail]);

  const fetchUserDetail = async () => {
    try {

      setUserDetail(null);

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/user/get-user-detail/${user_id}`
      );

      let userData = response.data.user;

      setUserDetail(userData);

      const userFormData = {
        ...updatedUser,
        username: userData.username, 
        email: userData.email
      };
      setUpdatedUser(userFormData);

    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const fetchUserRoles = async (manualReload: boolean = false) => {
    try {

      if(manualReload) {
        setIsLoading(true);
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/get-all-user-roles?email=${userDetail.email}`
      );

      let newData = response.data;

      if (newData.length !== 0) {
        setUserRoles(newData);
      } else {
        setUserRoles([]);
      }

      if(manualReload) {
        setIsLoading(false);
      }

    } catch (error) {
      setUserRoles([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      console.error("Error fetching data", error);
      console.error("Error fetching user roles:", error);
    }
  };

  const fetchHierarchy = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/role/get-all-hierarchy-levels"
      );

      setRoleHierarchy(response.data);
    } catch (error) {
      console.error("Error fetching hierarchy data", error);
      setRoleHierarchy([]);
    }
  };

  const getMerchantOptions = async () => {
    let merchantsOptionsArray = [];
    try {
      if (currentUserRole === "SuperAdmin") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/merchant/get-all-merchants`
        );
        if (response.data.merchants && response.data.merchants.payload) {
          merchantsOptionsArray = [
            { id: "", name: "-- Please Select --" },
            ...response.data.merchants.payload,
          ];
        } else {
          console.error("Invalid response format for get-all-merchants");
          merchantsOptionsArray = [{ id: "", name: "-- Please Select --" }];
        }
      } else {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const cognitoId = idToken.payload.sub;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/merchant/get-merchants-for-user?cognitoId=${cognitoId}`
        );

        if (response.data.merchants && response.data.merchants.payload) {
          merchantsOptionsArray = [
            { id: "", name: "-- Please Select --" },
            ...response.data.merchants.payload,
          ];
        } else {
          console.error("Invalid response format for get-merchants-for-user");
          merchantsOptionsArray = [{ id: "", name: "-- Please Select --" }];
        }
      }

      setMerchants(merchantsOptionsArray);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setMerchants([]);
    }
  };

  const getSubaccountOptions = async (merchantId: any) => {
    setIsSetSubaccounts(true);
    try {
      if (currentUserRole === "SuperAdmin") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/subaccount/get-all-subaccounts-for-merchant?merchantId=${merchantId}`
        );
        
        setIsSetSubaccounts(false);

        return [
          { id: "", suba_name: "-- Please Select --" },
          ...response.data.subaccounts.payload,
        ];
      } else {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const cognitoId = idToken.payload.sub;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/subaccount/get-subaccounts-for-user?cognitoId=${cognitoId}&merchantId=${merchantId}`
        );

        setIsSetSubaccounts(false);
        
        return [
          { id: "", suba_name: "-- Please Select --" },
          ...response.data.subaccounts.payload,
        ];
      }
    } catch (error) {
      setIsSetSubaccounts(false);
      console.error(error);
      return [];
    }
  };

  const getSubaccountForGetName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subaccount/get-all-subaccounts`
      );
      setSubaccountForGetNameList(response.data.subaccounts.payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setSubaccountForGetNameList([]);
    }
  };

  const getRoleOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/role/get-all-hierarchy-levels`
      );
      const roles = response.data;

      const currentRole = roles.find(
        (role: any) => role.name === currentUserRole
      );

      if (!currentRole) {
        return ["-- Please Select --"];
      }

      const roleOptionsArray = ["-- Please Select --"];

      roles.forEach((role: any) => {
        if (role.hierarchy_level < currentRole.hierarchy_level) {
          roleOptionsArray.push(role.name);
        }
      });

      setRoleOptions(roleOptionsArray);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching role options:", error);      
      setRoleOptions([]);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        const merchantId = params.row.merchantId;
        const merchant = merchants.filter((x: any) => x.id === merchantId);
        const merchantName = merchant.length > 0 ? merchant[0].name : "";
        return merchantName;
      },
    },
    {
      field: "subaccountName",
      headerName: "Subaccount",
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        const subaccountId = params.row.subaccountId;
        const subaccount = subaccountForGetNameList.filter((x: any) => x.id === subaccountId);
        const subaccountName = subaccount.length > 0 ? subaccount[0].suba_name : "";
        return subaccountName;
      },
    },
    {
      field: "role",
      headerName: "Role",
      maxWidth: 100,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<UserRole, string>) => (
        <div className="flex items-center">
          {canEdit(currentUserRole, params.row.role) && (
            <>
              <Tooltip title="Edit Role" arrow placement="top">
                <IconButton
                  disabled={isDeleting || isDeleting}
                  onClick={() => handleEditRole(params.row)}
                >
                  <AiOutlineForm className="h-5 w-5 text-navy-700" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Unassign Role" arrow placement="top">
                <IconButton
                  disabled={isDeleting || isDeleting}
                  onClick={() => handleDeleteRole(params.row)}
                >
                  <AiOutlineClose className="h-5 w-5 text-red-500" />
                </IconButton>
              </Tooltip>
              {/* <Button
                isDisabled={isDeleting || isDeleting}
                className="ml-4 rounded bg-blue-500 px-2 py-1 text-xs font-bold text-white hover:bg-blue-700"
                onClick={() => handleEditRole(params.row)}
              >
                Edit
              </Button>
              <Button
                isDisabled={isDeleting || isDeleting}
                className="ml-2 rounded bg-red-500 px-2 py-1 text-xs font-bold text-white hover:bg-red-700"
                onClick={() => handleDeleteRole(params.row)}
              >
                Delete
              </Button> */}
            </>
          )}
        </div>
      ),
    },
  ];

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start text-md',
    row: ''
  };

  return (
    <div className="flex h-full flex-col">
      <>
        <SweetAlert2 {...swalProps} />

        <div className="mt-3 mb-3 flex items-center justify-end">
          <Button
            onClick={() => navigate(`/${userRole}/user-management`)}
            className="btn btn-default"
          >
            <FaAngleLeft />&nbsp;Back to Users
          </Button>
        </div>
        <Tabs className="card">
          <TabList className="rounded-tl-custom rounded-tr-custom flex rounded-[20px]">
            <Tab
              onClick={() => setCurrentTab("details")}
              className={
                "rounded-tl-custom rounded-tr-custom bg-white px-6 py-3 " +
                (currentTab === "details" ? "border-b-4 border-green-500" : "")
              }
            >
              <strong>Details</strong>
            </Tab>
            <Tab
              onClick={() => setCurrentTab("accounts")}
              className={
                "rounded-tl-custom rounded-tr-custom bg-white px-6 py-3 " +
                (currentTab === "accounts"
                  ? "border-b-4 border-green-500"
                  : "")
              }
            >
              <strong>Accounts</strong>
            </Tab>
          </TabList>
          <TabPanels className="rounded-bl-custom rounded-br-custom border-t border-gray-200 bg-white">
            <TabPanel className="rounded-bl-custom rounded-br-custom p-8">
              {isLoading && userDetail === null && canEdit && currentUserRole ? (
                <>
                  <div
                    className="mb-3 h-5 animate-pulse rounded-md bg-gray-200"
                    style={{ animationDuration: "0.6s" }}
                  ></div>
                  <div
                    className="mb-3 h-5 animate-pulse rounded-md bg-gray-200"
                    style={{ animationDuration: "0.6s" }}
                  ></div>
                  <div
                    className="mb-3 h-5 animate-pulse rounded-md bg-gray-200"
                    style={{ animationDuration: "0.6s" }}
                  ></div>
                  <div
                    className="mb-3 h-5 animate-pulse rounded-md bg-gray-200"
                    style={{ animationDuration: "0.6s" }}
                  ></div>
                </>
              ) : (
                <>
                  <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-1/2 md:pr-4">
                      <InputField
                        variant="auth"
                        extra="mb-3 shadow-sm"
                        label="Username *"
                        placeholder=""
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="off"
                        value={updatedUser.username}
                        onChange={handleInputChange}
                        disabled={isUpdating}
                      />

                      <InputField
                        variant="auth"
                        extra="mb-3 shadow-sm"
                        label="Email *"
                        placeholder=""
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="off"
                        value={updatedUser.email}
                        onChange={handleInputChange}
                        disabled={isUpdating}
                      />

                      <div>
                        <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                          Created Date
                        </label>

                        <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                          {formatDate(userDetail.created_date)}
                        </div>
                      </div>

                      <div>
                        <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                          Latest Login
                        </label>

                        <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                          {userDetail.latest_login ? formatDate(userDetail.latest_login) : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end p-4">
                    <Button
                      onClick={handleUpdateUser}
                      className={`btn btn-primary ${
                        isUpdating
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                      disabled={isUpdating}
                      isLoading={isUpdating}
                      loadingText="Updating User..."
                    >
                      Update
                    </Button>
                  </div>
                </>
              )}
            </TabPanel>
            <TabPanel className="rounded-bl-custom rounded-br-custom p-8">
              <div className="flex mb-6">
                <Button
                  isDisabled={isLoading || isDeleting}
                  onClick={handleAddRole}
                  className="btn btn-action"
                >
                  <FaPlus />
                  &nbsp;Add Role
                </Button>
              </div>
              <Box
              >
                <DataGrid
                  sx={{
                    '& .MuiDataGrid-cell': {
                      padding: '8px 16px',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      padding: '8px 16px',
                    },
                    "& .MuiTablePagination-input": {
                      marginRight: "-20px"
                    },
                    "& .MuiTablePagination-displayedRows": {
                      display: "none"
                    },
                  }}
                  loading={isLoading || userRoles.length === 0 || merchants.length === 0 || subaccountForGetNameList.length === 0}
                  rows={userRoles || []}
                  columns={columns}
                  getRowId={(row) =>
                    `${row.role}-${row.merchantId}-${row.subaccountId}`
                  }
                  rowCount={userRoles.length}
                  sortingMode="server"
                  paginationMode="server"
                  pageSizeOptions={[10, 25, 50, 100]}
                  rowHeight={40}
                  columnHeaderHeight={46}
                  paginationModel={{ page: page, pageSize: pageSize }}
                  onPaginationModelChange={(newValue) =>
                    setPaginationModel(newValue)
                  }
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnMenu
                  classes={customDataGridStyles}
                  autoHeight
                  hideFooter={page === 0 && userRoles.length < pageSize}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
        {userDetail && (
          <>
            <AddRoleModal
              showForm={showAddRoleModal}
              setShowForm={setShowAddRoleModal}
              userEmail={userDetail.email}
              roleOptions={roleOptions}
              merchants={merchants}
              getSubaccountOptions={getSubaccountOptions}
              isSetSubaccounts={isSetSubaccounts}
              fetchUserRoles={fetchUserRoles}
            />
            <EditRoleModal
              showForm={showEditRoleModal}
              setShowForm={setShowEditRoleModal}
              selectedRole={selectedRole}
              setUserRoles={setUserRoles}
              roleOptions={roleOptions}
              username={userDetail.username}
              email={userDetail.email}
              editUserChannel={editUserChannel}
              fetchUserRoles={fetchUserRoles}
            />
            <DeleteRoleModal
              username={userDetail.username}
              email={userDetail.email}
              merchantName={deleteRoleMerchantName}
              subaccountname={deleteRoleSubaccountName}
              showForm={showDeleteRoleModal}
              setShowForm={setShowDeleteRoleModal}
              selectedRole={selectedRole}
              setUserRoles={setUserRoles}
              editUserChannel={editUserChannel}
              fetchUserRoles={fetchUserRoles}
              setIsDeleting={setIsDeleting}
            />
          </>
        )}
      </>
    </div>
  );
};

export default UserDetail;
