import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import { IoMdPaperPlane } from "react-icons/io";

const ReceiverResendKYCConfirmModal: React.FC<{
    receiverToResendKYC: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
    setSwalProps: any;
}> = ({ isOpen, onClose, triggerReload, receiverToResendKYC, setSwalProps }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const closeModal = async () => {
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const postData = {
                receiver_id: receiverToResendKYC.id
            }

            await axios.post(
                process.env.REACT_APP_API_URL + `/api/receiver/resend-kyc`,
                postData
            )
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "KYC link has been successfully resent.",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    return (
        <>
            {receiverToResendKYC &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody>
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to resend KYC link for the receiver?</h1>
                                <div className="mb-[20px]">
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">First Name:</span> {receiverToResendKYC.first_name}
                                    </div>
                                    {receiverToResendKYC.middle_name && receiverToResendKYC.middle_name !== "" &&
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">Middle Name:</span> {receiverToResendKYC.middle_name}
                                        </div>
                                    }
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">last Name:</span> {receiverToResendKYC.last_name}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Email:</span> {receiverToResendKYC.email}
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isLoading={isProcessing}
                                        loadingText='Sending...'
                                        className="btn btn-primary">
                                        <IoMdPaperPlane className="h-4 w-4" />&nbsp;Resend
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default ReceiverResendKYCConfirmModal;
