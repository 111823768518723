/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "routes/hooks/useAuth";
import Logo from "assets/img/logo-light.svg";
import { Button } from "@chakra-ui/react";
import Card from "components/card";

const SubaccountSelection: React.FC = () => {
  const [subaccounts, setSubaccounts] = useState<
    { id: string; name: string }[]
  >([]);
  const [selectedSubaccount, setSelectedSubaccount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const merchantId = location.state?.merchantId;
  const cognitoId = location.state?.cognitoId;
  const userRole = location.state?.userRole;
  const reSelectSubAccount = location.state?.reSelectSubAccount
  const { isAuthenticated } = useAuth();
  const userEmail = localStorage.getItem("userEmail");
  const apiToken = localStorage.getItem("apiToken");

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      sessionStorage.setItem("attemptedRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    if (sessionStorage.getItem("attemptedRefresh") === "true") {
      sessionStorage.removeItem("attemptedRefresh");
      if (reSelectSubAccount) {
        navigate("/");
      } else {
        navigate("/auth/sign-in");
      }
    }

    if (!cognitoId || !merchantId) {
      navigate("/auth/sign-in");
      return;
    }

    const fetchSubaccounts = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subaccount/get-all-subaccounts-user?cognitoId=${cognitoId}&merchantId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`
          }
        }
      );
      const data = response.data.subaccounts;

      if (userRole) {
        if (Array.isArray(data)) {
          setSubaccounts(
            data.map((subaccount: any) => ({
              id: subaccount.id,
              name: subaccount.name,
            }))
          );
          if (data.length === 1) {
            localStorage.setItem("subaccountId", data[0].id);
            localStorage.setItem("subaccountName", data[0].name);
            localStorage.setItem("subaccountsCount", '1');
            const selectedRole = userRole.find(
              (role: any) =>
                role.merchantId === merchantId &&
                role.subaccountId === data[0].id
            );
            if (selectedRole) {
              localStorage.setItem("userRole", selectedRole.role);
              navigate(`/${selectedRole.role.toLowerCase()}/default`);
            }
          } else {
            localStorage.setItem("subaccountsCount", data.length.toString());
          }
        }
      }
      setIsLoading(false);
    };
    if (cognitoId && merchantId) {
      fetchSubaccounts();
    }
  }, [merchantId, navigate, cognitoId, isAuthenticated, userRole]);

  const handleSubaccountSelection = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    localStorage.setItem("userRole", userRole);
    localStorage.setItem("subaccountId", event.target.value);

    let subaccount = subaccounts.filter((x: any) => x.id === event.target.value)[0];
    localStorage.setItem("subaccountName", subaccount.name);

    setSelectedSubaccount(event.target.value);
  };

  const handleConfirmSelection = async () => {
    setIsWaiting(true);
    setError(null);
    try {
      const selectedRole = userRole.find(
        (role: any) =>
          role.merchantId === merchantId &&
          role.subaccountId === selectedSubaccount
      );
      if (selectedRole) {
        localStorage.setItem("userRole", selectedRole.role);

        const username = localStorage.getItem("username");
        const systemLogDetail = localStorage.getItem("subaccountName");
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/admin/systemlog/create`,
          { suba_id: selectedRole.subaccountId, action: 'login', details: systemLogDetail, logged_role: selectedRole.role.toLowerCase(), logged_username: username },
          {
            headers: {
              Authorization: `Bearer ${apiToken}`
            }
          }
        );

        navigate(`/${selectedRole.role.toLowerCase()}/default`);
      }
    } catch (error) {
      setIsWaiting(false);
      setError("Something went wrong, please contact administrator");
      console.error("Error fetching user info: ", error);
    }
  };

  return Logo ? (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0">
        <div className="mb-8 flex items-center justify-center">
          <img src={Logo} alt="Payswiftly" width="229px" />
        </div>
        <Card
          className="bg-white p-8 sm:rounded-md shadow-3xl"
        >
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Select Subaccount
          </h4>
          <p className="mb-12 text-base text-gray-600">
            Please select which subaccount you want to manage
          </p>
          <p className="mb-3 text-sm text-gray-600">
            Signed in as{" "}
            <strong className="text-green-500">{userEmail}</strong>
          </p>
          <div className="text-black-600 mb-3 flex justify-center text-base">
            <select
              disabled={subaccounts.length === 0 || isLoading}
              value={selectedSubaccount}
              onChange={handleSubaccountSelection}
              className="custom-select relative mt-2 block w-full rounded-lg border border-gray-200 bg-white p-2.5 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
              {!subaccounts ? (
                <option>Loading...</option>
              ) : subaccounts.length === 0 || isLoading ? (
                <option>Retreiving Subaccounts...</option>
              ) : (
                <>
                  <option disabled value="">-- Please Select --</option>
                  {subaccounts.map((subaccount) => (
                    <option key={subaccount.id} value={subaccount.id}>
                      {subaccount.name}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>

          {error &&
            <p className="flex justify-center text-sm text-red-500 my-2">
              {error}
            </p>
          }

          <Button
            onClick={handleConfirmSelection}
            isDisabled={!selectedSubaccount}
            isLoading={isWaiting}
            loadingText="Please wait..."
            className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
          >
            Continue
          </Button>
        </Card>
      </div>
    </div>
  ) : null;
};

export default SubaccountSelection;
