/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import ActivityLogList from "./components/ActivityLogList";
import ActivityLogSearch from "./components/ActivityLogSearch";
import { FaPlus } from "react-icons/fa";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Filters {
  [key: string]: any;
}

const initFrom = moment().subtract(1, 'month');
const initTo = moment();
const initialDateRangeFilter = [initFrom.toDate(), initTo.toDate()];

const rowPerPage = 10;

const initialFilters: Filters = {
  from: initFrom,
  to: initTo,
  merchant_id: "",
  suba_id: "",
  role: "",
  action: "",
  username: "",
  details: ""
};

interface PayloadType {
  [key: string]: any;
}

const ActivityLog = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDateRangeFilter);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);

  const [merchantList, setMerchantList] = useState<any>(null);
  const [subaccountList, setSubaccountList] = useState<any>(null);
  const [isSubaccountListLoading, setIsSubaccountListLoading] = useState<boolean>(false);
  const [merchantIdToGetSubaccount, setMerchantIdToGetSubaccount] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("merchantIdToFilterActivityLogs");
      localStorage.removeItem("subaccountIdToFilterActivityLogs");
      setIsLoading(true);
      await fetchSystemLogs({
        page: 0,
        limit: rowPerPage,
        lastkey: "",
        ...filters,
      });
      await fetchMerchants();
      setIsLoading(false);
      setIsFilterLoading(false);
      setIsSubaccountListLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      fetchSubaccounts();
    };

    fetchData();
  }, [merchantIdToGetSubaccount]);

  const fetchSystemLogs = async (payload: PayloadType = {}, isReset = false) => {
    try {

      const formattedFromDate = moment(!isReset ? selectedDates[0] : initFrom, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
      const formattedToDate = moment(!isReset ? selectedDates[1] : initTo, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');

      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        from: formattedFromDate,
        to: formattedToDate,
      };

      const merchantId = localStorage.getItem("merchantIdToFilterActivityLogs");
      const subaccountId = localStorage.getItem("subaccountIdToFilterActivityLogs");

      if (merchantId && merchantId !== "" && merchantId !== undefined) {
        queryObject.merchant_id = merchantId
      }

      if (subaccountId && subaccountId !== "" && subaccountId !== undefined) {
        delete queryObject.merchant_id
        queryObject.suba_id = subaccountId
      }

      if (payload?.role !== "" && payload?.role !== undefined) {
        queryObject.role = payload?.role
      }

      if (payload?.username !== "" && payload?.username !== undefined) {
        queryObject.username = payload?.username
      }

      if (payload?.action !== "" && payload?.action !== undefined) {
        queryObject.action = payload?.action
      }

      if (payload?.details !== "" && payload?.details !== undefined) {
        queryObject.details = payload?.details
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/api/admin/systemlog?${queryString}`
      );

      let newData = response.data.systemlogs?.payload;

      if (newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);

      let newLastKey = response.data.systemlogs?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount(newPage * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      ...filters,
    };

    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        ...filters,
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchSystemLogs(newFilters);
  };

  // const handleClickCreate = () => {
  //   navigate(`/${userRole}/account-management/create`);
  // };

  const handleSearchSubmit = () => {
    setIsLoading(true);
    setIsFilterLoading(true);
    setCurrentLastKey("");
    fetchSystemLogs({ page: 0, limit: rowPerPage, lastkey: "", ...filters });
    setIsFilterLoading(false);
  };

  const handleSearchClear = () => {
    handleResetTable();
  };

  const handleResetTable = () => {
    localStorage.removeItem("merchantIdToFilterActivityLogs");
    localStorage.removeItem("subaccountIdToFilterActivityLogs");
    setIsLoading(true);
    setIsFilterLoading(true);
    setFilters(initialFilters);
    setLastKeys([]);
    setCurrentLastKey("");
    setSubaccountList(null);
    setMerchantIdToGetSubaccount(null);
    setPage(0);
    setSelectedDates(initialDateRangeFilter);
    fetchSystemLogs({
      page: 0,
      limit: rowPerPage,
      lastkey: "",
      ...initialFilters,
    }, true);
    setIsFilterLoading(false);
  };

  const fetchMerchants = async () => {
    try {
      let requestUrl = `/api/merchant/get-all-merchants`;

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.merchants?.payload;

      setMerchantList(data);
    } catch (error) {
      console.error("Error fetching merchants", error);
    }
  };

  const fetchSubaccounts = async () => {
    if (merchantIdToGetSubaccount !== null) {
      try {
        setIsSubaccountListLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
          `/api/subaccount/get-all-subaccounts-for-merchant?merchantId=${merchantIdToGetSubaccount}`
        );

        const data = response.data.subaccounts?.payload;

        setSubaccountList(data);
        setIsSubaccountListLoading(false);
      } catch (error) {
        console.error("Error fetching subaccounts", error);
      }
    }
  };

  return (
    <>
      <ActivityLogSearch
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClear={handleSearchClear}
        isFilterLoading={isFilterLoading}
        merchantList={merchantList}
        subaccountList={subaccountList}
        isSubaccountListLoading={isSubaccountListLoading}
        setMerchantIdToGetSubaccount={setMerchantIdToGetSubaccount}
      />
      <ActivityLogList
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
      />
    </>
  );
};

export default ActivityLog;
