import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import AutoComplete from 'react-select';
import { RangeDatepicker } from "chakra-dayzed-datepicker";

const InputStyle =
  "daterange-picker font-bold text-navy-700 h-10 mt-2 w-full items-center shadow-sm rounded-md border bg-white/0 p-3 text-sm outline-none shadow-sm border-gray-300 dark:!border-white/10 dark:text-white";

const dateRangePropsConfigs = {
  dateNavBtnProps: {
    variant: "outline",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "rgb(233 236 239 / 1)", // Default border color
      borderRadius: "2px",
      _hover: {
        background: "#4fb06d", // Background color on hover
        color: "white",
      },
    },
    isInRangeBtnProps: {
      background: "#65e68d", // Background color for dates in the selected range
      color: "white", // Text color for dates in the selected range
    },
    selectedBtnProps: {
      background: "#4fb06d", // Background color for selected date
      color: "white", // Text color for selected date
    },
    todayBtnProps: {
      // color: "#422afb", // Background color for today's date
      // fontWeight: "bold",
    },
  },
  inputProps: {
    minWidth: "230px",
    className: InputStyle,
  },
  popoverCompProps: {
    popoverContentProps: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Apply custom box shadow
    },
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: "#81E6D9", // Border color for the calendar wrapper
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: "5px",
    },
    dividerProps: {
      display: "none",
    },
  },
  weekdayLabelProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
  dateHeadingProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
};

const ActivityLogSearch: React.FC<{
  isLoading: boolean;
  isFilterLoading: boolean;
  filters: any;
  setFilters: any;
  setSelectedDates: any;
  selectedDates: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
  merchantList: any;
  subaccountList: any;
  isSubaccountListLoading: boolean;
  setMerchantIdToGetSubaccount: any;
}> = ({ isLoading, filters, setFilters, setSelectedDates, selectedDates, handleSearchSubmit, handleSearchClear, isFilterLoading, merchantList, subaccountList, isSubaccountListLoading, setMerchantIdToGetSubaccount }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const [merchantAutoCompleteOptions, setMerchantAutoCompleteOptions] = useState<any>(null);
  const merchantAutoCompleteRef = useRef(null);

  const [subaccountAutoCompleteOptions, setSubaccountAutoCompleteOptions] = useState<any>(null);
  const subaccountAutoCompleteRef = useRef(null);

  useEffect(() => {
    if (merchantList !== null) {
      let rebuildListArray: any = [];
      if (merchantList.length > 0) {
        merchantList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.name
          }
          rebuildListArray.push(newObj);
        });
      }

      setMerchantAutoCompleteOptions(rebuildListArray);
    }
  }, [merchantList]);

  useEffect(() => {
    if (subaccountList !== null) {
      let rebuildListArray: any = [];
      if (subaccountList.length > 0) {
        subaccountList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.suba_name
          }
          rebuildListArray.push(newObj);
        });
      }

      setSubaccountAutoCompleteOptions(rebuildListArray);
    }
  }, [subaccountList]);

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });
  };

  const handleMerchantAutoCompleteChange = (item: any) => {
    localStorage.setItem("merchantIdToFilterActivityLogs", "");
    if (item !== null) {
      localStorage.setItem("merchantIdToFilterActivityLogs", item.value);
      setFilters({ ...filters, subaccount_id: "" });
      setMerchantIdToGetSubaccount(item.value);
    } else {
      setFilters({ ...filters, subaccount_id: "" });
      setMerchantIdToGetSubaccount(null);
    }
  };

  const noMerchantOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Merchant not found for "{inputValue}"</div>;
  };

  const handleSubaccountAutoCompleteChange = (item: any) => {
    localStorage.setItem("subaccountIdToFilterActivityLogs", "");
    if (item !== null) {
      localStorage.setItem("subaccountIdToFilterActivityLogs", item.value);
    }
  };

  const noSubaccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Subaccount not found for "{inputValue}"</div>;
  };

  const handleClear = () => {
    if (userRole === 'superadmin') {
      merchantAutoCompleteRef.current.clearValue();
      subaccountAutoCompleteRef.current.clearValue();
      setSubaccountAutoCompleteOptions(null);
    }
    handleSearchClear();
  };

  return (
    <Box
      className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="flex-1 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Date
          </label>
          <div className="date-picker-custom">
            <RangeDatepicker
              disabled={isFilterLoading}
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
              propsConfigs={dateRangePropsConfigs}
              configs={{
                dateFormat: 'MMM d, yyyy'
              }}
              maxDate={new Date()}
            />
          </div>
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Merchant
          </label>
          <AutoComplete
            ref={merchantAutoCompleteRef}
            className="autocomplete-override mt-2"
            isDisabled={isFilterLoading || merchantAutoCompleteOptions === null}
            placeholder={merchantAutoCompleteOptions === null ? 'Retrieving...' : 'All'}
            options={merchantAutoCompleteOptions}
            classNamePrefix="search-payout-receiver"
            onChange={(item: any) => handleMerchantAutoCompleteChange(item)}
            noOptionsMessage={noMerchantOptionsMessageRenderer}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Subaccount
          </label>
          <AutoComplete
            ref={subaccountAutoCompleteRef}
            className="autocomplete-override mt-2"
            isDisabled={isFilterLoading || subaccountAutoCompleteOptions === null}
            placeholder={isSubaccountListLoading ? 'Retrieving...' : 'All'}
            options={subaccountAutoCompleteOptions}
            classNamePrefix="search-payout-receiver"
            onChange={(item: any) => handleSubaccountAutoCompleteChange(item)}
            noOptionsMessage={noSubaccountOptionsMessageRenderer}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Role
          </label>
          <Select isDisabled={isLoading} id="role" value={filters.role} onChange={handleInputChange} className="h-10  mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="superadmin">superadmin</option>
            <option value="admin">admin</option>
            <option value="merchant">merchant</option>
            <option value="user">user</option>
          </Select>
        </div>
      </div>
      <div className="flex w-full">

        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Username
          </label>
          <InputField
            disabled={isLoading}
            variant="auth"
            extra="shadow-sm"
            label=""
            placeholder=""
            id="username"
            type="text"
            autoComplete="off"
            value={filters.username}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Action
          </label>
          <Select isDisabled={isLoading} id="action" value={filters.action} onChange={handleInputChange} className="h-10  mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="login">login</option>
            <option value="payout">payout</option>
            <option value="batch">batch</option>
            <option value="receiver">receiver</option>
            <option value="receiver account">receiver account</option>
            <option value="user">user</option>
          </Select>
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Details
          </label>
          <InputField
            disabled={isLoading}
            variant="auth"
            extra="shadow-sm"
            label=""
            placeholder=""
            id="details"
            type="text"
            autoComplete="off"
            value={filters.details}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex-1 p-2 pt-0 flex justify-end items-end">
          <Button
            isDisabled={isLoading}
            onClick={() => handleClear()}
            className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading}
            onClick={() => handleSearchSubmit()}
            className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
          >
            Search Logs
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default ActivityLogSearch;
