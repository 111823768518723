/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from "react";
import InputField from "components/fields/InputField";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import Logo from "assets/img/logo-light.svg";
import Card from "components/card";

const initialData: {
  password: string;
  confirm_password: string;
} = {
  password: "",
  confirm_password: ""
};

export default function ForgotPassword() {

  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const otpInputRefs = useRef<HTMLInputElement[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Payswiftly | Settlement System";
  }, []);

  const isFormValid = email !== "";
  const isResetFormValid =
    formData.password.trim() !== "" &&
    formData.confirm_password.trim() !== "" &&
    (otp.length === 6 ? true : false);

  const handleSubmit = async () => {
    setError(null);
    if (isFormValid) {
      setIsLoading(true);
      try {
        const response = await Auth.forgotPassword(email);
        setIsLoading(false);
        setIsRequestSuccess(true);
        console.log("Password reset code sent to email:", response.CodeDeliveryDetails);
      } catch (error: any) {
        console.error("Error request reset password:", error);
        setError("Email address not recognized");
        setIsLoading(false);
      }
    }
  };

  const handleResetPasswordSubmit = async () => {
    setIsLoading(true);
    setError(null);

    if (formData.password !== formData.confirm_password) {
      setIsLoading(false);
      setError("Passwords do not match");
      return;
    }

    try {
      const otpToVerify = otp.join("");
      await Auth.forgotPasswordSubmit(email, otpToVerify, formData.password);
      setSuccess("Your password has been successfully reset");
    } catch (error) {
      setIsLoading(false);
      if (String(error).includes("InvalidPasswordException")) {
        setError("Invalid password format");
      } else if (String(error).includes("CodeMismatchException")) {
        setError("Invalid verification code");
      } else if (String(error).includes("LimitExceededException")) {
        setError("Attempt limit exceeded, please try again later");
      } else {
        setError("Something went wrong, please contact administrator");
      }
      console.error("Error confirming new password:", error);
    }
  };

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleOTPInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setError(null);
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus the next input field if the current one has a value and is not the last one
      if (value && index < 5) {
        otpInputRefs.current[index + 1].focus();
      } else if (index === 5) {
        // Blur the input to remove the cursor when the 6th digit is entered
        otpInputRefs.current[index].blur();
      }
    }
  };

  const handleOTPInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    setError(null);
    if (e.key === 'Backspace') {
      if (otp[index] === '' && index > 0) {
        // Move focus to the previous input field if the current one is empty
        otpInputRefs.current[index - 1].focus();
      } else {
        // Clear the current input and keep the focus
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  const handleOTPInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    setError(null);
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    const newOtp = pasteData.split('').map((char, i) => (i < 6 ? char : otp[i]));
    setOtp(newOtp);

    // Focus the next input field after paste, but blur the last one
    pasteData.split('').forEach((_, i) => {
      if (i < 5 && pasteData[i]) {
        otpInputRefs.current[i + 1].focus();
      } else if (i === 5) {
        otpInputRefs.current[i].blur();
      }
    });
  };

  const navigateToSignin = async () => {
    localStorage.clear();
    navigate(`/auth/sign-in`);
  };

  return Logo ? (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0">
        <div className="mb-12 flex items-center justify-center">
          <img src={Logo} alt="Payswiftly" width="229px" />
        </div>
        <Card
          className="bg-white p-8 sm:rounded-md  shadow-cs"
        >
          {!isRequestSuccess &&
            <>
              <h4 className="text-2xl font-bold mb-2 text-navy-700">
                Reset Your Password
              </h4>
              <p className="mb-8 text-base text-gray-600">
                Enter your email address, and we'll send you instructions to reset your password
              </p>

              <InputField
                disabled={isLoading}
                variant="auth"
                extra="mb-3"
                label="Email"
                placeholder=""
                id="email"
                type="text"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {error && (
                <p className="flex my-2 justify-center text-sm text-red-500">{error}</p>
              )}

              <Button
                onClick={handleSubmit}
                isDisabled={!isFormValid || isLoading}
                isLoading={isLoading}
                loadingText="Please wait..."
                className="linear w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
              >
                Send
              </Button>
            </>
          }
          {isRequestSuccess && success === null &&
            <>
              <h4 className="text-2xl font-bold mb-2 text-navy-700">
                Reset Your Password
              </h4>
              <p className="mb-8 text-base text-gray-600">
                Set up your new password below
              </p>

              <InputField
                disabled={isLoading}
                variant="auth"
                extra="mb-3"
                label="Password"
                placeholder=""
                id="password"
                type="password"
                autoComplete="off"
                value={formData.password}
                onChange={(e) => handleInputChange(e)}
              />

              <InputField
                disabled={isLoading}
                variant="auth"
                extra="mb-3"
                label="Confirm Password"
                placeholder=""
                id="confirm_password"
                type="password"
                autoComplete="off"
                value={formData.confirm_password}
                onChange={(e) => handleInputChange(e)}
              />

              <p className="flex justify-center items-center mb-2 mt-6 text-sm">
                Please enter the verification code sent to your email
              </p>
              <div className="flex text-center justify-center items-center w-full mt-2 space-x-2 mb-6">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    ref={(el) => {
                      if (el) otpInputRefs.current[index] = el;
                    }}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleOTPInputChange(e, index)}
                    onKeyDown={(e) => handleOTPInputKeyDown(e, index)}
                    onPaste={handleOTPInputPaste}
                    className="w-10 h-12 text-center border border-gray-300 shadow-sm outline-none rounded-md"
                  />
                ))}
              </div>

              {error && (
                <p className="flex mb-2 justify-center text-sm text-red-500">{error}</p>
              )}

              <Button
                onClick={handleResetPasswordSubmit}
                isDisabled={!isResetFormValid || isLoading}
                isLoading={isLoading}
                loadingText="Please wait..."
                className="linear w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
              >
                Submit
              </Button>
            </>
          }

          {success && (
            <p className="flex mb-2 justify-center text-sm text-green-500">{success}</p>
          )}

          <div className="mt-3 flex justify-center items-center">
            <a onClick={() => navigateToSignin()} className="text-sm text-navy-700 hover:underline hover:cursor-pointer text-center">Back to sign in</a>
          </div>
        </Card>
      </div>
    </div>
  ) : null;
}
